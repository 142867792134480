import React, { useState, useEffect, useCallback } from 'react';
import { 
  Grid, 
  Typography, 
  Box, 
  Button, 
  LinearProgress, 
  Paper, 
  Container,
  Chip,
  Avatar,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { 
  DirectionsCar as CarIcon,
  LocalShipping as ShippingIcon,
  Speed as SpeedIcon,
  Security as SecurityIcon,
  LocalActivity as ActivityIcon,
  ArrowForward as ArrowForwardIcon,
  CheckCircle as CheckCircleIcon
} from '@mui/icons-material';
import useStyles from '../styles/styles';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { calcEst, showEst, optionsRequired } from '../common/sharedFunctions';
import { FONT_FAMILY } from "../common/sharedFunctions";
import { colors } from './Theme/WebTheme';

const Section = (props) => {
  const settings = useSelector(state => state.settingsdata.settings);
  const classes = useStyles();
  const { t } = useTranslation();
  const cartypes = useSelector(state => state.cartypes);
  const [data, setData] = useState([]);
  const auth = useSelector(state => state.auth);
  const [loggedIn, setLoggedIn] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  
  // State to track dynamic availability
  const [dynamicAvailability, setDynamicAvailability] = useState({});

  // Function to generate a random availability value between 30 and 95
  const generateAvailability = useCallback(() => {
    return Math.floor(Math.random() * 66) + 30; // Generates values between 30 and 95
  }, []);

  // Update availability every 2 minutes
  useEffect(() => {
    if (cartypes.cars) {
      // Initial data setup with dynamic availability
      const initialAvailability = cartypes.cars.reduce((acc, car) => {
        acc[car.id] = generateAvailability();
        return acc;
      }, {});
      setDynamicAvailability(initialAvailability);

      // Set up interval to update availability every 2 minutes
      const intervalId = setInterval(() => {
        setDynamicAvailability(prev => {
          const newAvailability = {...prev};
          cartypes.cars.forEach(car => {
            newAvailability[car.id] = generateAvailability();
          });
          return newAvailability;
        });
      }, 120000); // 2 minutes = 120000 milliseconds

      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [cartypes.cars, generateAvailability]);

  useEffect(() => {
    if (cartypes.cars) {
      setData(cartypes.cars.sort((a, b) => a.pos - b.pos));
    } else {
      setData([]);
    }
  }, [cartypes.cars]);

  useEffect(()=>{
    if(auth.profile && auth.profile.uid){
      setLoggedIn(true);
    }else{
      setLoggedIn(false);
    }
  },[auth.profile]);

  const navigate = useNavigate();
  const role = props.role;
  const color = props.color;
  
  const serviceFeatures = [
    {
      icon: <ShippingIcon sx={{ color: '#3498db', fontSize: '40px' }} />,
      title: t('pruduct_section_heading_1'),
      description: t('product_section_1'),
      gradient: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)'
    },
    {
      icon: <SpeedIcon sx={{ color: '#2ecc71', fontSize: '40px' }} />,
      title: t('pruduct_section_heading_2'),
      description: t('product_section_2'),
      gradient: 'linear-gradient(135deg, #ff6a00 0%, #ee0979 100%)'
    },
    {
      icon: <SecurityIcon sx={{ color: '#e74c3c', fontSize: '40px' }} />,
      title: t('pruduct_section_heading_3'),
      description: t('product_section_3'),
      gradient: 'linear-gradient(135deg, #11998e 0%, #38ef7d 100%)'
    },
    {
      icon: <ActivityIcon sx={{ color: '#f39c12', fontSize: '40px' }} />,
      title: t('pruduct_section_heading_4'),
      description: t('product_section_4'),
      gradient: 'linear-gradient(135deg, #834d9b 0%, #d04ed6 100%)'
    }
  ];

  const getProgressBarColor = (availability) => {
    if (availability < 30) return 'error';
    if (availability < 70) return 'warning';
    return 'success';
  };

  const renderCarsSection = () => {
    return (
      <Box 
        sx={{ 
          background: 'linear-gradient(135deg, #f5f7fa 0%, #e9ecef 100%)', 
          py: 8, 
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            sx={{ 
              textAlign: 'center', 
              fontWeight: 700, 
              color: colors.CAR_BOX_ODD,
              mb: 6,
              fontFamily: FONT_FAMILY,
              position: 'relative',
              zIndex: 2
            }}
          >
            {('AVAILABLE VEHICLES')}
          </Typography>

          <Grid container spacing={4} justifyContent="center">
            {data.map((car, index) => {
              const availability = dynamicAvailability[car.id] || generateAvailability();
              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Paper 
                    elevation={6} 
                    sx={{ 
                      height: '100%', 
                      display: 'flex', 
                      flexDirection: 'column',
                      borderRadius: 3,
                      overflow: 'hidden',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-15px)',
                        boxShadow: '0 15px 30px rgba(0,0,0,0.15)'
                      }
                    }}
                  >
                    <Box 
                      sx={{ 
                        position: 'relative', 
                        height: 250, 
                        overflow: 'hidden' 
                      }}
                    >
                      <img 
                        src={car.image} 
                        alt={car.name} 
                        style={{ 
                          width: '100%', 
                          height: '100%', 
                          objectFit: 'cover',
                          transition: 'transform 0.3s ease'
                        }} 
                      />
                      <Chip 
                        label={car.name} 
                        color="primary" 
                        sx={{ 
                          position: 'absolute', 
                          bottom: 10, 
                          left: 10,
                          fontFamily: FONT_FAMILY
                        }} 
                      />
                    </Box>

                    <Box 
                      sx={{ 
                        p: 3, 
                        flexGrow: 1, 
                        display: 'flex', 
                        flexDirection: 'column' 
                      }}
                    >
                      <Box sx={{ width: '100%', mb: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                          <Typography 
                            variant="body2" 
                            color="text.secondary" 
                            sx={{ 
                              fontFamily: FONT_FAMILY
                            }}
                          >
                            {('Availability')}
                          </Typography>
                          <Typography 
                            variant="body2" 
                            color="text.secondary" 
                            sx={{ 
                              fontFamily: FONT_FAMILY
                            }}
                          >
                            {availability}%
                          </Typography>
                        </Box>
                        <LinearProgress 
                          variant="determinate" 
                          value={availability} 
                          color={getProgressBarColor(availability)}
                          sx={{ 
                            height: 10, 
                            borderRadius: 5,
                            backgroundColor: (theme.palette.mode === 'light') ? '#e0e0e0' : '#333',
                            '& .MuiLinearProgress-bar': {
                              borderRadius: 5,
                              transition: 'width 0.6s ease'
                            }
                          }} 
                        />
                      </Box>

                      <Button
                        variant="contained"
                        endIcon={<ArrowForwardIcon />}
                        sx={{ 
                          mt: 'auto', 
                          fontFamily: FONT_FAMILY,
                          borderRadius: 30,
                          px: 3,
                          py: 1,
                          background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                          '&:hover': {
                            background: 'linear-gradient(45deg, #2196F3 10%, #21CBF3 90%)'
                          }
                        }}
                        onClick={() => {
                          if(loggedIn){
                            navigate(
                              ((role && role === 'driver') || 
                              (calcEst && !showEst && !optionsRequired && 
                              (role === 'admin' || role === 'fleetadmin'))) 
                              ? '/bookings' 
                              : '/addbookings', 
                              { state: { carData: car } }
                            )
                          } else {
                            navigate('/login')
                          }
                        }}
                      >
                        {t('book_now')}
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Box>
    );
  };

  const renderFeaturesSection = () => {
    return (
      <Box 
        sx={{ 
          background: 'linear-gradient(135deg, #000428 0%, #004e92 100%)', 
          py: { xs: 6, sm: 8, md: 10 }, 
          color: 'white',
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            sx={{ 
              textAlign: 'center', 
              fontWeight: 700, 
              color: 'white',
              mb: { xs: 4, sm: 6, md: 8 },
              fontFamily: FONT_FAMILY
            }}
          >
            {t('best_service_provider')}
          </Typography>

          <Grid 
            container 
            spacing={{ xs: 2, sm: 3, md: 4 }} 
            justifyContent="center"
            sx={{ 
              '& > .MuiGrid-item': { 
                display: 'flex', 
                justifyContent: 'center',
                mb: { xs: 2, sm: 3, md: 4 }
              }
            }}
          >
            {serviceFeatures.map((feature, index) => (
              <Grid 
                item 
                xs={12} 
                sm={6} 
                md={3} 
                key={index}
                gap={10}
              >
                <Box 
                  sx={{ 
                    background: feature.gradient,
                    borderRadius: 4,
                    p: { xs: 2, sm: 3 },
                    width: '100%',
                    maxWidth: 300,
                    height: '90%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.05)'
                    },
                    boxShadow: '0 10px 20px rgba(0,0,0,0.2)'
                  }}
                >
                  <Avatar 
                    sx={{ 
                      width: { xs: 60, sm: 80 }, 
                      height: { xs: 60, sm: 80 }, 
                      mb: 3, 
                      background: 'rgba(255,255,255,0.2)',
                      boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
                    }}
                  >
                    {feature.icon}
                  </Avatar>
                  <Typography 
                    variant="h5" 
                    sx={{ 
                      color: 'white', 
                      mb: 2,
                      fontWeight: 600,
                      fontFamily: FONT_FAMILY,
                      fontSize: { xs: '1.2rem', sm: '1.5rem' }
                    }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      color: 'rgba(255,255,255,0.8)',
                      fontFamily: FONT_FAMILY,
                      mb: 2,
                      fontSize: { xs: '0.8rem', sm: '1rem' }
                    }}
                  >
                    {feature.description}
                  </Typography>
                  <CheckCircleIcon 
                    sx={{ 
                      color: 'rgba(255,255,255,0.7)', 
                      fontSize: { xs: 30, sm: 40 },
                      mt: 'auto' 
                    }} 
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    );
  };

  return (
    <div>
      {renderCarsSection()}
      {renderFeaturesSection()}
    </div>
  );
};

export default Section;