import React, { useState, useEffect } from "react";
import { 
    Box, 
    Container, 
    Grid, 
    Typography, 
    Paper, 
    useMediaQuery, 
    useTheme 
} from "@mui/material";
import { styled } from "@mui/material/styles";

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import { useTranslation } from "react-i18next";

// Styled components for enhanced UI
const SectionPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    boxShadow: '0 8px 24px rgba(0,0,0,0.1)',
    background: 'linear-gradient(135deg, #f5f7fa 0%, #f5f7fa 100%)',
    marginBottom: theme.spacing(4),
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
}));

// Static data with translation keys
const items = [
    { translateKey: 'our_mission' },
    { translateKey: 'urgent_parcels' },
    { translateKey: 'breakdown_vehicles' },
    { translateKey: 'temperature_controlled' },
    { translateKey: 'cargo_goods' },
    { translateKey: 'moving_services' },
    { translateKey: 'payment' },
    { translateKey: 'online_service_management' },
    { translateKey: 'satisfaction_guaranteed' }
];

const services = [
    { translateKey: 'aboutUs_description_our_services_1' },
    { translateKey: 'aboutUs_description_our_services_2' },
    { translateKey: 'aboutUs_description_our_services_3' },
    { translateKey: 'aboutUs_description_our_services_4' },
    { translateKey: 'aboutUs_description_our_services_5' }
];

const weServe = [
    { translateKey: 'aboutUs_description_benefit_withUs_1' },
    { translateKey: 'aboutUs_description_benefit_withUs_2' },
    { translateKey: 'aboutUs_description_benefit_withUs_3' },
    { translateKey: 'aboutUs_description_benefit_withUs_4' }
];

export default function AboutUs(props) {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoaded(true);
        }, 100);
        return () => clearTimeout(timeout);
    }, []);

    return (
        <Box sx={{ 
            minHeight: '100vh', 
            display: 'flex', 
            flexDirection: 'column',
            background: 'linear-gradient(135deg, #f5f7fa 0%, #e9ecef 100%)'
        }}>
            <Header 
                color="transparent" 
                rightLinks={<HeaderLinks />} 
                fixed 
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
            />

            <Parallax 
                small 
                filter 
                image={require("assets/img/image_leaving.jpg").default} 
            />

            <Container maxWidth="lg" sx={{ flexGrow: 1, py: 6 }}>
                <div>
                    {/* Title Section */}
                    <div>
                        <Typography 
                            variant="h3" 
                            align="center" 
                            gutterBottom 
                            sx={{ 
                                color: theme.palette.primary.main, 
                                fontWeight: 'bold',
                                mb: 4,
                                opacity: loaded ? 1 : 0,
                                transition: 'opacity 0.3s ease-in-out'
                            }}
                        >
                            {t('aboutUs')}
                        </Typography>
                    </div>

                    {/* Who We Are Section */}
                    <SectionPaper elevation={3} sx={{ opacity: loaded ? 1 : 0 }}>
                        <div>
                            <Grid container spacing={4} alignItems="center">
                                <Grid item xs={12} md={6}>
                                    <img 
                                        src={require("assets/img/aboutus-image.jpg").default} 
                                        alt="About Us" 
                                        style={{ 
                                            width: '100%', 
                                            borderRadius: theme.spacing(2),
                                            boxShadow: '0 8px 24px rgba(0,0,0,0.1)',
                                            opacity: loaded ? 1 : 0,
                                            transition: 'opacity 0.3s ease-in-out'
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography 
                                        variant="h4" 
                                        gutterBottom 
                                        sx={{ 
                                            color: theme.palette.primary.main,
                                            mb: 2 
                                        }}
                                    >
                                        {t('aboutUs_title_who_we_are')}
                                    </Typography>
                                    <Typography 
                                        variant="body1" 
                                        paragraph 
                                        sx={{ 
                                            color: theme.palette.text.secondary,
                                            lineHeight: 1.6 
                                        }}
                                    >
                                        {t('aboutUs_description_who_we_are')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </SectionPaper>

                    {/* Our Mission and Services */}
                    <SectionPaper elevation={3} sx={{ opacity: loaded ? 1 : 0 }}>
                        <div>
                            <Typography 
                                variant="h4" 
                                gutterBottom 
                                sx={{ 
                                    color: theme.palette.primary.main, 
                                    mb: 3,
                                    textAlign: 'center' 
                                }}
                            >
                                {t('aboutUs_title_our_mission')}
                            </Typography>
                            <Grid container spacing={3}>
                                {items.map((item, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <Typography 
                                            variant="h6" 
                                            sx={{ 
                                                color: theme.palette.primary.dark,
                                                mb: 1,
                                                opacity: loaded ? 1 : 0,
                                                transition: 'opacity 0.3s ease-in-out'
                                            }}
                                        >
                                            {t(`aboutUs_title_${item.translateKey}`)}
                                        </Typography>
                                        <Typography 
                                            variant="body2" 
                                            sx={{ 
                                                color: theme.palette.text.secondary,
                                                opacity: loaded ? 1 : 0,
                                                transition: 'opacity 0.3s ease-in-out'
                                            }}
                                        >
                                            {t(`aboutUs_description_${item.translateKey}`)}
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </SectionPaper>

                    {/* Our Services */}
                    <SectionPaper elevation={3} sx={{ opacity: loaded ? 1 : 0 }}>
                        <div>
                            <Typography 
                                variant="h4" 
                                gutterBottom 
                                sx={{ 
                                    color: theme.palette.primary.main, 
                                    mb: 3,
                                    textAlign: 'center' 
                                }}
                            >
                                {t('aboutUs_title_our_services')}
                            </Typography>
                            <Grid container spacing={2}>
                                {services.map((service, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Typography 
                                            variant="body1" 
                                            sx={{ 
                                                color: theme.palette.text.primary,
                                                '&::before': {
                                                    content: '"• "',
                                                    color: theme.palette.primary.main,
                                                    fontWeight: 'bold',
                                                    mr: 1
                                                },
                                                opacity: loaded ? 1 : 0,
                                                transition: 'opacity 0.3s ease-in-out'
                                            }}
                                        >
                                            {t(service.translateKey)}
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </SectionPaper>

                    {/* Benefits */}
                    <SectionPaper elevation={3} sx={{ opacity: loaded ? 1 : 0 }}>
                        <div>
                            <Typography 
                                variant="h4" 
                                gutterBottom 
                                sx={{ 
                                    color: theme.palette.primary.main, 
                                    mb: 3,
                                    textAlign: 'center' 
                                }}
                            >
                                {t('aboutUs_title_benefit_withUs')}
                            </Typography>
                            <Grid container spacing={2}>
                                {weServe.map((serve, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Typography 
                                            variant="body1" 
                                            sx={{ 
                                                color: theme.palette.text.primary,
                                                '&::before': {
                                                    content: '"✓ "',
                                                    color: theme.palette.primary.main,
                                                    fontWeight: 'bold',
                                                    mr: 1
                                                },
                                                opacity: loaded ? 1 : 0,
                                                transition: 'opacity 0.3s ease-in-out'
                                            }}
                                        >
                                            {t(serve.translateKey)}
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </SectionPaper>
                </div>
            </Container>

            <Footer />
        </Box>
    );
}