import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Box, IconButton, Container, Fade } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { calcEst, showEst, optionsRequired, FONT_FAMILY } from '../common/sharedFunctions';
import { colors as col } from "../components/Theme/WebTheme";
import useStyles from '../styles/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// Import the images
import slide1 from '../assets/img/slide1.jpg';
import slide2 from '../assets/img/slide2.jpg';
import slide3 from '../assets/img/slide3.jpg';
import slide4 from '../assets/img/slide4.jpg';
import slide5 from '../assets/img/slide5.jpg';

const Hero = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const navigate = useNavigate();
  const role = props.role;
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const auth = useSelector(state => state.auth);

  // Array to hold slide data
  const slides = [
    {
      image: slide1,
      title: t('bike_delivery'),
      description: t('bike_delivery_txt'),
      buttonText: t('book_your_ride_menu'),
    },
    {
      image: slide2,
      title: t('refane_transportation'),
      description: t('refane_transportation_txt'),
      buttonText: t('book_your_ride_menu'),
    },
    {
      image: slide3,
      title: t('hilux_transportation'),
      description: t('hilux_transportation_txt'),
      buttonText: t('book_your_ride_menu'),
    },
    {
      image: slide5,
      title: t('dinah_transportation'),
      description: t('dinah_transportation_txt'),
      buttonText: t('book_your_ride_menu'),
    },
    {
      image: slide4,
      title: t('break_down'),
      description: t('break_down_txt'),
      buttonText: t('book_your_ride_menu'),
    }
  ];

  // Automatically cycle through the slides every 7 seconds
  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 7000);
    
    return () => clearInterval(slideInterval);
  }, [slides.length]);

  useEffect(() => {
    if (auth.profile && auth.profile.uid) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [auth.profile]);

  // Handle Next Slide
  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  // Handle Previous Slide
  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };

  return (
    <Box
      className={classes.heroBox}
      sx={{
        backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${slides[currentSlide].image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
        transition: 'background-image 1.5s ease-in-out',
      }}
      style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}
    >
      <Container maxWidth="lg">
        <Fade in={true} timeout={1000}>
          <Grid 
            container 
            spacing={3} 
            className={classes.gridContainer} 
            sx={{ 
              alignItems: 'center', 
              position: 'relative', 
              zIndex: 10 
            }}
          >
            {/* Previous Arrow */}
            <Grid item xs={1} sx={{ display: { xs: 'none', md: 'block' } }}>
              <IconButton 
                onClick={handlePrevSlide} 
                sx={{ 
                  color: 'white', 
                  '&:hover': { 
                    backgroundColor: 'rgba(255,255,255,0.2)' 
                  } 
                }}
              >
                <ArrowBackIosIcon fontSize="large" />
              </IconButton>
            </Grid>
            
            <Grid item xs={12} md={10} sx={{ textAlign: 'center' }}>
              <Typography 
                variant="h3" 
                fontWeight={700} 
                className={classes.titleMain} 
                sx={{ 
                  color: col.WHITE, 
                  fontFamily: FONT_FAMILY,
                  textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
                  marginBottom: 2,
                }}
              >
                {slides[currentSlide].title}
              </Typography>
              <Typography 
                variant="h5" 
                className={classes.subtitleMain} 
                sx={{ 
                  color: col.WHITE, 
                  fontFamily: FONT_FAMILY,
                  marginBottom: 4,
                  opacity: 0.9,
                }}
              >
                {slides[currentSlide].description}
              </Typography> 
              <Button
                variant="contained"
                color="primary"
                sx={{ 
                  width: '250px', 
                  fontSize: '18px', 
                  fontFamily: FONT_FAMILY,
                  padding: '12px 24px',
                  borderRadius: '30px',
                  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 6px 8px rgba(0,0,0,0.2)',
                  }
                }}
                onClick={(e) => {
                  e.preventDefault();
                  if (loggedIn) {
                    navigate(((role && role === 'driver') || (calcEst && !showEst && !optionsRequired && (role === 'admin' || role === 'fleetadmin'))) ? '/bookings' : '/addbookings');
                  } else {
                    navigate('/login');
                  }
                }}
              >
                {role === 'driver' || (calcEst && !showEst && !optionsRequired && (role === 'admin' || role === 'fleetadmin')) ? t('info') : slides[currentSlide].buttonText}
              </Button>
            </Grid>
            
            {/* Next Arrow */}
            <Grid item xs={1} sx={{ display: { xs: 'none', md: 'block' }, textAlign: 'right' }}>
              <IconButton 
                onClick={handleNextSlide} 
                sx={{ 
                  color: 'white', 
                  '&:hover': { 
                    backgroundColor: 'rgba(255,255,255,0.2)' 
                  } 
                }}
              >
                <ArrowForwardIosIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </Fade>
      </Container>

      {/* Responsive Controls for small screens */}
      <Box 
        sx={{ 
          display: { xs: 'flex', md: 'none' }, 
          justifyContent: 'space-between', 
          padding: '0 20px', 
          position: 'absolute', 
          bottom: 20, 
          left: 0, 
          right: 0, 
          zIndex: 20 
        }}
      >
        <IconButton 
          onClick={handlePrevSlide} 
          sx={{ 
            color: 'white', 
            backgroundColor: 'rgba(0,0,0,0.3)', 
            '&:hover': { 
              backgroundColor: 'rgba(0,0,0,0.5)' 
            } 
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton 
          onClick={handleNextSlide} 
          sx={{ 
            color: 'white', 
            backgroundColor: 'rgba(0,0,0,0.3)', 
            '&:hover': { 
              backgroundColor: 'rgba(0,0,0,0.5)' 
            } 
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Hero;
