import React from 'react';
import { 
  Grid, 
  Typography, 
  Box, 
  Container, 
  Paper, 
  Button, 
  Avatar,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { 
  PhoneIphone as PhoneIcon, 
  GetApp as DownloadIcon, 
  Person as PersonIcon,
  Security as SecurityIcon,
  DeviceHub as DeviceHubIcon
} from '@mui/icons-material';
import HandsOnMobile from '../assets/img/handsonmobile.png';
import HandsOnMobile1 from '../assets/img/handsonmobile1.png';
import AppleStoreBadge from '../assets/img/appstore.webp';
import PlayStoreBadge from '../assets/img/playstore.webp';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { FONT_FAMILY } from 'common/sharedFunctions';
import { colors } from '../components/Theme/WebTheme';

const DownloadApp = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const settings = useSelector(state => state.settingsdata.settings);
  const { t } = useTranslation();

  const appFeatures = [
    {
      icon: <PersonIcon sx={{ fontSize: 40, color: '#2196f3' }} />,
      title: 'Easy Sign-Up',
      description: 'Multiple login options including phone, email, and social accounts',
      gradient: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)'
    },
    {
      icon: <SecurityIcon sx={{ fontSize: 40, color: '#4caf50' }} />,
      title: 'Secure Authentication',
      description: 'Advanced security with SMS and multi-factor verification',
      gradient: 'linear-gradient(135deg, #11998e 0%, #38ef7d 100%)'
    },
    {
      icon: <DeviceHubIcon sx={{ fontSize: 40, color: '#ff9800' }} />,
      title: 'Cross-Platform',
      description: 'Seamless experience across mobile and web platforms',
      gradient: 'linear-gradient(135deg, #ff6a00 0%, #ee0979 100%)'
    }
  ];

  return (
    <Box 
      sx={{ 
        background: 'linear-gradient(135deg, #f5f7fa 0%, #e9ecef 100%)', 
        py: { xs: 4, md: 8 },
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          {/* Mobile App Showcase */}
          <Grid 
            item 
            xs={12} 
            md={6} 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                mb: 4,
                position: 'relative',
                mr:10
              }}
            >
              <Box 
                component="img"
                src={HandsOnMobile} 
                alt="Driver App" 
                sx={{ 
                  width: { xs: '40%', md: '50%' }, 
                  transform: 'rotate(-10deg)',
                  boxShadow: '0 15px 30px rgba(0,0,0,0.2)',
                  borderRadius: 4,
                  zIndex: 2
                }}
              />
              <Box 
                component="img"
                src={HandsOnMobile1} 
                alt="Customer App" 
                sx={{ 
                  width: { xs: '40%', md: '50%' }, 
                  position: 'absolute', 
                  right: 0,
                  transform: 'rotate(10deg)',
                  boxShadow: '0 15px 30px rgba(0,0,0,0.2)',
                  borderRadius: 4,
                  zIndex: 1
                }}
              />
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Typography 
                variant="h5" 
                sx={{ 
                  fontFamily: FONT_FAMILY, 
                  fontWeight: 600,
                  color: colors.CAR_BOX_ODD,
                  mb: 2
                }}
              >
                {t('mobile_apps_on_store')}
              </Typography>
              <Box 
                sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  gap: 2,
                  mt: 3
                }}
              >
                {settings?.AppleStoreLink && (
                  <Button
                    href={settings.AppleStoreLink}
                    variant="outlined"
                    startIcon={<PhoneIcon />}
                    sx={{ 
                      borderRadius: 4,
                      px: 3,
                      py: 1,
                      fontFamily: FONT_FAMILY
                    }}
                  >
                    App Store
                  </Button>
                )}
                {settings?.PlayStoreLink && (
                  <Button
                    href={settings.PlayStoreLink}
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    sx={{ 
                      borderRadius: 4,
                      px: 3,
                      py: 1,
                      fontFamily: FONT_FAMILY,
                      background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)'
                    }}
                  >
                    Play Store
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>

          {/* App Features */}
          <Grid item xs={12} md={6}>
            <Typography 
              variant="h4" 
              sx={{ 
                fontFamily: FONT_FAMILY, 
                fontWeight: 700,
                color: colors.CAR_BOX_ODD,
                mb: 4,
                textAlign: { xs: 'center', md: 'left' }
              }}
            >
              App Features
            </Typography>
            
            <Grid container spacing={3}>
              {appFeatures.map((feature, index) => (
                <Grid item xs={12} sm={6} md={12} key={index}>
                  <Paper 
                    elevation={4}
                    sx={{ 
                      p: 3, 
                      borderRadius: 4,
                      background: feature.gradient,
                      color: 'white',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.05)'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Avatar 
                        sx={{ 
                          mr: 2, 
                          background: 'rgba(255,255,255,0.2)',
                          width: 60,
                          height: 60
                        }}
                      >
                        {feature.icon}
                      </Avatar>
                      <Typography 
                        variant="h6" 
                        sx={{ 
                          fontFamily: FONT_FAMILY, 
                          fontWeight: 600 
                        }}
                      >
                        {feature.title}
                      </Typography>
                    </Box>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        fontFamily: FONT_FAMILY,
                        color: 'rgba(255,255,255,0.8)'
                      }}
                    >
                      {feature.description}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default DownloadApp;
